let config = {};
let environment = "demo";
if (environment == "local") {
  config = {
    adminBackendUrl: "http://localhost:5000/admin/",
  };
} else {
  config = {
    adminBackendUrl: "https://api.rempic.com/admin/",
  };
}

export default config;